<template>
    <header class="bg-dark px-10 py-6">
        <div class="flex lg:flex-1">
            <slot name="logo" />
        </div>
    </header>
    <div class="flex sm:justify-center align-items-center">
        <div class="w-full sm:max-w-screen-sm mt-6">
            <slot />
        </div>
    </div>
</template>
<script setup></script>
